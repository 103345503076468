<template>
  <b-modal
    size="md"
    id="modal-kunde-duplicates-merge"
    scrollable
    centered
    :title="$t('KUNDEN.DUBLETTEN.MERGEKUNDEN')"
    @hide="onHide($event)"
  >
    {{ $t('KUNDEN.DUBLETTEN.SHOULDKUNDENMERGED') }}
    <div class="mt-3 row">
      <b-input-group
        aria-describedby="input-live-feedback-1"
        class="col-md-12 mb-3"
        size="lg"
        prepend="1. Kunden Nummer"
      >
        <b-form-input v-model="kunde1Id" :state="kunde1IdState" type="number"></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback-1">
          {{ $t('KUNDEN.DUBLETTEN.GUELTIGEKUNDENNUMMER') }}
        </b-form-invalid-feedback>
      </b-input-group>

      <b-input-group
        aria-describedby="input-live-feedback-2"
        class="col-md-12"
        size="lg"
        prepend="2. Kunden Nummer"
      >
        <b-form-input v-model="kunde2Id" :state="kunde2IdState" type="number"></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback-2">
          {{ $t('KUNDEN.DUBLETTEN.GUELTIGEKUNDENNUMMER') }}
        </b-form-invalid-feedback>
      </b-input-group>
    </div>
    <template #modal-footer="{ ok, cancel }">
      <b-button variant="success" @click="onModalOkay()">{{ $t('DUBLETTEN.MERGE') }}</b-button>
      <b-button variant="danger" @click="cancel()">{{ $t('COMMON.ABBRECHEN') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import { CREATE_KUNDE_DUPLICATE_AND_ACCEPT } from '@/core/kunden/stores/kunden.module';
export default {
  data() {
    return {
      kunde1Id: null,
      kunde1IdState: null,
      kunde2Id: null,
      kunde2IdState: null,
    };
  },
  methods: {
    onModalOkay() {
      const kunde1Id = Number(this.kunde1Id);
      const kunde2Id = Number(this.kunde2Id);
      this.kunde1IdState = typeof kunde1Id === 'number' && kunde1Id > 0;
      this.kunde2IdState = typeof kunde2Id === 'number' && kunde2Id > 0;
      if (this.kunde1IdState && this.kunde2IdState) {
        this.$store
          .dispatch(CREATE_KUNDE_DUPLICATE_AND_ACCEPT, {
            kunde1Id: kunde1Id,
            kunde2Id: kunde2Id,
          })
          .then(() => {
            this.$emit('duplicate-created');
            this.$bvModal.hide('modal-kunde-duplicates-merge');
            this.toast(this.$t('WORKFLOWS.COMMON.SAVED'));
          });
      }
    },
    onHide($event) {
      if ($event.trigger !== 'ok' && $event.trigger !== 'event') {
        this.toast(this.$t('COMMON.AKTIONABGEBROCHEN'), { variant: 'secondary' });
      }
    },
  },
};
</script>

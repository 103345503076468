<template>
  <b-modal
    size="xl"
    :id="modalId"
    scrollable
    centered
    :title="$t(modalTitelLocalizationString)"
    @hide="onHide($event)"
  >
    {{ $t(`KUNDEN.DUBLETTEN_CONFIRMATION_${trigger === 0 ? 'MERGE' : 'CANCEL'}_MSG`) }}
    <KundeDublettenConfirmationCompareTable :kunde="kunde" :duplicateKunde="duplicateKunde" />

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="success"
        @click="
          ok();
          $emit('onOkayClick', {
            kundeId: kunde.id,
            duplicateKundeId: duplicateKunde.id,
            trigger: trigger,
          });
        "
      >
        Ja
      </b-button>
      <b-button variant="danger" @click="cancel()">Nein</b-button>
    </template>
  </b-modal>
</template>

<script>
import KundeDublettenConfirmationCompareTable from '@/components/kunden/dubletten-confirmation-table-compare-table.vue';
export default {
  components: {
    KundeDublettenConfirmationCompareTable,
  },
  props: ['modalId', 'trigger', 'kunde', 'duplicateKunde'],
  data() {
    return {};
  },
  methods: {
    onHide($event) {
      if ($event.trigger !== 'ok') {
        this.toast('Aktion abgebrochen', { variant: 'secondary' });
      }
    },
  },
  computed: {
    modalTitelLocalizationString() {
      return `KUNDEN.DUBLETTEN_CONFIRMATION_${
        this.trigger === 0 ? 'MERGE' : this.trigger === 1 ? 'CANCEL' : 'UNMERGE'
      }_TITLE`;
    },
  },
};
</script>
